import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";


function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  const [client, setClient] = React.useState(null as any);

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
  }, []);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {/* <SidePanel skin="floating" width="100%"> */}
        {/* <SidePanel.Header title="SEO with AI"  subtitle="Get found on Google with ease"/> */}
        <Card>
          <MarketingLayout
            size="tiny"
            title="Discover AI tools to help you get found on Google"
            description="Automatically complete alt text, meta tags, and more with AI-powered SEO tools."
            actions={<Button size="small" onClick={()=>{
              client.dashboard.navigate("b38598d2-640a-41bc-bceb-2639d3f7e13d");
            }}>Get Started</Button>}
          />
        </Card>
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
